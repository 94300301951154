.button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    padding: 8px 24px;
    background-color: $grey;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 1.8rem;
    font-weight: $medium;
    text-align: center;
    line-height: 1.33;
    white-space: nowrap;
    color: $marine;
    transition: background-color .1s ease-in-out;

    &:hover {
        background-color: darken($grey, 5%);
        cursor: pointer;
    }
}

.button-square {
    width: 48px;
    height: 48px;
}

.button-primary {
    background-color: $leaf;
    color: $white;

    &:hover {
        background-color: darken($leaf, 5%);
    }
}

.button-secondary {
    background-color: $marine;
    color: $white;

    &:hover {
        background-color: darken($marine, 5%);
    }
}

.button-tertiary {
    background-color: $white;
    border-color: $grey;

    &:hover {
        background-color: darken($white, 5%);
    }
}

.button-transparent {
    background-color: transparent;
    border-color: transparent;

    &:hover {
        background-color: transparent;
    }
}