.p {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-top: map-get($spaces, $size);
            padding-bottom: map-get($spaces, $size);
            padding-left: map-get($spaces, $size);
            padding-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-top: map-get($spaces, $size);
                    padding-bottom: map-get($spaces, $size);
                    padding-left: map-get($spaces, $size);
                    padding-right: map-get($spaces, $size);
                }
            }
        }
    }
}

.py {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-top: map-get($spaces, $size);
            padding-bottom: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-top: map-get($spaces, $size);
                    padding-bottom: map-get($spaces, $size);
                }
            }
        }
    }
}

.px {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-left: map-get($spaces, $size);
            padding-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-left: map-get($spaces, $size);
                    padding-right: map-get($spaces, $size);
                }
            }
        }
    }
}

.pt {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-top: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-top: map-get($spaces, $size);
                }
            }
        }
    }
}

.pb {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-bottom: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-bottom: map-get($spaces, $size);
                }
            }
        }
    }
}

.pl {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-left: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-left: map-get($spaces, $size);
                }
            }
        }
    }
}

.pr {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            padding-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    padding-right: map-get($spaces, $size);
                }
            }
        }
    }
}
