.cookiebanner {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    left: 20px;
    width: 640px;
    height: auto;
    max-width: calc(100% - 40px);
    clip: auto;
    clip-path: none;
    overflow: visible;
    white-space: normal;

    &.is-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: radial-gradient(circle at bottom left, rgba(#000, .1), rgba(#000, 0) 50%);
        background-size: cover;
        background-repeat: no-repeat;
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
    }
}

.cookiebanner-card {
    position: relative;
    z-index: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 1s ease, transform 1s ease;

    .cookiebanner.is-hidden & {
        opacity: 0;
        transform: translate3d(0, 24px, 0);
    }

    &.is-hidden {
        display: none;
    }
}
