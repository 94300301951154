.header {
    position: relative;
    z-index: 1;
}

.header-logo {
    width: 48px;
    height: 48px;
}

.nav-header {
    > ul {
        display: flex;
        align-items: center;

        > li {
            & + li {
                margin-left: 64px;
            }

            > a:not([class]) {
                font-size: 1.8rem;
                font-weight: $medium;
                color: $darkgrey;
                transition: color .1s ease-in-out;

                &:hover {
                    color: $marine;
                }
            }

            &.is-active {
                > a:not([class]) {
                    font-weight: $bold;
                    color: $marine;
                }
            }
        }
    }
}

.nav-mobile {
    > a {
        position: fixed;
        right: 20px;
        z-index: 100;

        @include media-query(md) {
            right: 40px;
        }

        > .icon {
            width: 24px;
            height: 24px;
            fill: $marine;
        }
    }

    > ul {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($earth, .96);
        backdrop-filter: blur(4px);
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > li {
            & + li {
                margin-top: 24px;
            }

            > a:not([class]) {
                font-size: 2rem;
                color: $darkgrey;
            }

            &.is-active {
                > a:not([class]) {
                    font-weight: $bold;
                    color: $marine;
                }
            }
        }
    }
}