.icon-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.icon {
    display: block;
    width: 16px;
    height: 16px;
    fill: $marine;
    flex: 0 0 auto;
}

.icon-color-white {
    fill: $white;
}

.icon-color-marine {
    fill: $marine;
}

.icon-color-grey {
    fill: $grey;
}

.icon-color-darkgrey {
    fill: $darkgrey;
}

.icon-color-star {
    fill: $star;
}

.icon-color-leaf {
    fill: $leaf;
}