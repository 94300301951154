.flex-column {
    flex-direction: column;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: column;
            }
        }
    }
}

.flex-column-reverse {
    flex-direction: column-reverse;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: column-reverse;
            }
        }
    }
}

.flex-row {
    flex-direction: row;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: row;
            }
        }
    }
}

.flex-row-reverse {
    flex-direction: row-reverse;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: row-reverse;
            }
        }
    }
}

.align-stretch {
    align-items: stretch;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: stretch;
            }
        }
    }
}

.align-start {
    align-items: flex-start;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: flex-start;
            }
        }
    }
}

.align-end {
    align-items: flex-end;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: flex-end;
            }
        }
    }
}

.align-center {
    align-items: center;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: center;
            }
        }
    }
}

.justify-stretch {
    justify-content: stretch;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: stretch;
            }
        }
    }
}

.justify-start {
    justify-content: flex-start;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: flex-start;
            }
        }
    }
}

.justify-end {
    justify-content: flex-end;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: flex-end;
            }
        }
    }
}

.justify-center {
    justify-content: center;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: center;
            }
        }
    }
}

.justify-between {
    justify-content: space-between;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: space-between;
            }
        }
    }
}

.justify-around {
    justify-content: space-around;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: space-around;
            }
        }
    }
}

.flex-grow {
    flex-grow: 1;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-grow: 1;
            }
        }
    }
}

.flex-shrink {
    flex-shrink: 1;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-shrink: 1;
            }
        }
    }
}