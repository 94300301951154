.block {
    &+& {
        padding-top: 50px;

        @include media-query(md) {
            padding-top: 75px;
        }

        @include media-query(lg) {
            padding-top: 100px;
        }
    }
}

.hero {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media-query(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        gap: 0 40px;
    }
}

.hero-body {
    position: relative;
    grid-area: left / left / right / right;
}

.hero-image {
    position: relative;
    z-index: 0;
    grid-area: right;
    width: 100%;
    height: 0%;
    padding-bottom: 56.25%;

    img {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:after {
        @include media-query(md) {
            content: "";
            display: block;
            width: 150%;
            height: 0;
            padding-bottom: 150%;
            background-color: $earth;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
        }
    }
}

.hero-movie {
    position: relative;
    z-index: 0;
    grid-area: right;
    width: 100%;
    height: 0%;
    padding-bottom: 56.25%;

    &-player {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-shadow: 0 4px 16px rgba($night, .075);
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &:after {
        @include media-query(md) {
            content: "";
            display: block;
            width: 150%;
            height: 0;
            padding-bottom: 150%;
            background-color: $earth;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
        }
    }
}

.testimonials-grid {
    gap: $grid-gutter;
}

.testimonial-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    flex: 0 0 auto;
    overflow: hidden;
}

.testimonial-body {
    width: 100%;
}

.testimonial-score {
    display: flex;
    align-items: center;
    gap: 4px;
}

.key-features-list {
    counter-reset: i;

    >details {
        counter-increment: i;

        &[open] {
            border: 1px solid $grey;
            border-radius: 5px;
            box-shadow: 0 4px 16px rgba($night, 0.075);

            &+details {
                border-top: 0;
            }

            >summary {
                &:before {
                    color: $leaf;
                }
            }
        }

        &+details {
            border-top: 1px solid $grey;
        }

        >summary {
            cursor: pointer;
            display: flex;

            &:before {
                content: "0" counter(i);
                display: block;
                margin: 0 10px 0 0;
                font-size: 1.8rem;
                font-weight: $bold;
                line-height: 1.33;
                color: $grey;
            }
        }
    }
}

.key-feature-image {
    &.is-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.feature-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $earth;
    flex: 0 0 auto;

    @include media-query(lg) {
        width: 120px;
        height: 120px;
    }
}

.feature-icon {
    width: 24px;
    height: 24px;

    @include media-query(lg) {
        width: 32px;
        height: 32px;
    }
}

.step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $earth;
    flex: 0 0 auto;
    font-size: 2.4rem;
    font-weight: 500;
    color: $marine;

    @include media-query(lg) {
        width: 120px;
        height: 120px;
    }
}

.pricetable-list {
    >li {
        &+li {
            margin: 8px 0 0;
        }
    }
}

.pricetable-icon {
    width: 24px;
    height: 24px;
}

.pricetable-button {
    width: 100%;
}

.price {
    display: flex;

    .is-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.price-unit {
    font-weight: $medium;
    font-size: 2.4rem;
    line-height: 1;
    color: $leaf;
    align-self: flex-start;
    margin-right: 2px;
    position: relative;
    top: 1px;
}

.price-number {
    font-weight: $black;
    font-size: 3.6rem;
    line-height: 1;
    color: $leaf;
    align-self: flex-start;
}

.price-quantity {
    line-height: 1;
    align-self: flex-end;
    margin-left: 2px;
    position: relative;
    bottom: 3px;
}

.text-w-image-breakout {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media-query(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        gap: 40px;
    }
}

.text-w-image-breakout-body {
    align-self: center;
    grid-area: left / left / right / right;
}

.text-w-image-breakout-image {
    align-self: center;
    position: relative;
    z-index: 0;
    grid-area: right;

    img {
        width: 100%;
    }
}

.brands-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 6.4rem;
}

.brand-link {
    display: inline-block;
    vertical-align: middle;
}