$grid-columns: 12;
$grid-gutter: 20px;
$grid-breakpoints: (
    xs: 360px,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1600px
);

$spaces: (
    auto: auto,
    none: 0,
    4: 0.4rem,
    8: 0.8rem,
    16: 1.6rem,
    20: 2rem,
    24: 2.4rem,
    32: 3.2rem,
    40: 4rem,
    48: 4.8rem,
    50: 5rem,
    56: 5.6rem,
    60: 6rem,
    64: 6.4rem,
    75: 7.5rem,
    80: 8rem,
    100: 10rem,
    120: 12rem
);

$white: #FFF;
$grey: #c6d2da;
$darkgrey: #618B95;
$earth: #F3EEE8;
$leaf: #32BAA0;
$marine: #0F4F5F;
$salmon: #F2C7B6;
$night: #111;
$star: #FFD027;

$normal: 400;
$medium: 500;
$bold: 700;
$black: 900;
