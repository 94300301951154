body {
    &.is-fixed {
        overflow: hidden;
    }
}

.page {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    >* {
        flex: 0 0 auto;
    }
}

h1,
h2 {
    font-weight: $bold;
    color: $marine;
}

h3,
h4,
h5,
h6 {
    font-weight: $medium;
    color: $marine;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    .rtxt &+& {
        margin: 2.4rem 0 0;
    }
}

ul,
ol {
    .rtxt & {
        list-style: none;
        padding-left: 1.6rem;

        >li {
            &+li {
                margin-top: 0.8rem;
            }
        }
    }
}

ul {
    .rtxt & {
        >li {
            &:before {
                content: "\2022";
                display: inline-block;
                margin-right: 0.8rem;
            }
        }
    }
}

ol {
    .rtxt & {
        counter-reset: alpha;

        >li {
            counter-increment: alpha;

            &:before {
                content: counters(alpha, ".") ". ";
            }
        }
    }
}

.link-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

a {
    &:not([class]) {
        .rtxt & {
            text-decoration: underline;
            color: $marine;
            transition: color 0.1s ease-in-out;

            &:hover {
                color: $leaf;
            }
        }
    }
}

h1 {
    font-size: 3.6rem;
}

h2 {
    font-size: 2.8rem;
}

h3 {
    font-size: 1.8rem;
}

hr {
    width: 100%;
    height: 1px;
    border: 0;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(to right, $grey 33%, transparent 0%);
    background-position: bottom;
    background-size: .8rem 1px;
    background-repeat: repeat-x;
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @include media-query(md) {
        padding-left: 40px;
        padding-right: 40px;
    }
}