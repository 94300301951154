.flex {
    display: flex;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: flex;
            }
        }
    }
}

.inline-flex {
    display: inline-flex;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: inline-flex;
            }
        }
    }
}

.block {
    display: block;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: block;
            }
        }
    }
}

.inline-block {
    display: inline-block;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: inline-block;
            }
        }
    }
}

.none {
    display: none;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: none;
            }
        }
    }
}