.footer {
    background-color: $earth;
    margin-top: auto;
}

.footer-logo {
    width: 48px;
    height: 48px;
    flex: 0 0 auto;
}

.nav-sitemap {
    > ul {
        > li {
            > a:not([class]) {
                text-decoration: underline;
                color: $marine;
                transition: color .1s ease-in-out;

                &:hover {
                    color: $leaf;
                }
            }

            &.is-active {
                > a:not([class]) {
                    font-weight: $bold;
                    color: $leaf;
                }
            }
        }
    }
}

.nav-social {
    > ul {
        display: flex;
        align-items: center;

        > li {
            & + li {
                margin-left: 16px;
            }
        }
    }
}

.nav-legal {
    > ul {
        display: flex;
        align-items: center;

        > li {
            & + li {
                margin-left: 24px;
            }

            > a:not([class]) {
                text-decoration: underline;
                transition: color .1s ease-in-out;

                &:hover {
                    color: $marine;
                }
            }

            &.is-active {
                > a:not([class]) {
                    font-weight: $bold;
                    color: $marine;
                }
            }
        }
    }
}

.nav-cta {
    > ul {
        > li {
            & + li {
                margin-top: 16px;
            }
        }
    }
}