.grid {
    display: grid;
    grid-template-columns: repeat($grid-columns, 1fr);
    grid-auto-rows: auto;
    gap: 0px $grid-gutter;
}

.grid-overview {
    gap: ($grid-gutter*2) $grid-gutter;
}

.col {
    @for $i from 1 through $grid-columns {
        &-#{$i} {
            grid-column: auto / span #{$i};
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @for $i from 1 through $grid-columns {
                &-#{$i}-#{$breakpoint} {
                    grid-column: auto / span #{$i};
                }
            }
        }
    }
}

.col-start {
    @for $i from 1 through $grid-columns {
        &-#{$i} {
            grid-column-start: #{$i};
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @for $i from 1 through $grid-columns {
                &-#{$i}-#{$breakpoint} {
                    grid-column-start: #{$i};
                }
            }
        }
    }
}

.order {
    @for $i from 1 through 99 {
        &-#{$i} {
            order: #{$i};
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @for $i from 1 through 99 {
                &-#{$i}-#{$breakpoint} {
                    order: #{$i};
                }
            }
        }
    }
}