.field-wrapper {
    margin-bottom: 24px;
}

.input-wrapper {
    position: relative;
}

.field-validation-error {
    position: absolute;
    top: calc(100% + 3px);
    left: 8px;
    width: auto;
    max-width: calc(100% - 16px);
    padding: 4px 8px;
    border-radius: 3px;
    background-color: red;
    font-size: 1.4rem;
    color: $white;

    &:before {
        content: '';
        display: block;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid red;
        position: absolute;
        top: -5px;
        left: 8px;
    }
}

.input-label {
    display: block;
    color: $marine;

    &:hover {
        cursor: pointer;
    }

    small {
        display: block;
        line-height: 1;
        color: $darkgrey;
    }
}

.input-text,
.input-textarea {
    display: block;
    width: 100%;
    border: 1px solid $grey;
    background-color: $white;
    min-height: 48px;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    color: $marine;

    &:focus {
        border-color: $salmon;
        outline: 2px solid $salmon;
        
        &::placeholder {
            color: $darkgrey;
        }
    }

    &::placeholder {
        color: $grey;
    }
}

.input-text {
    padding: 8px 16px;
}

.input-textarea {
    padding: 16px;
}

.input-switch {
    position: relative;

    input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:hover {
        cursor: pointer;
    }
}

.input-switch-bar {
    display: block;
    width: 76px;
    height: 40px;
    border-radius: 20px;
    background-color: $grey;
    transition: background-color .25s ease-in-out;

    input[type="checkbox"]:checked ~ & {
        background-color: $leaf;
    }
}

.input-switch-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 4px 16px rgba($night, .1);
    transition: transform .25s ease-in-out;

    input[type="checkbox"]:checked ~ & {
        transform: translateX(36px);
    }
}
