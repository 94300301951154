.m {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-top: map-get($spaces, $size);
            margin-bottom: map-get($spaces, $size);
            margin-left: map-get($spaces, $size);
            margin-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-top: map-get($spaces, $size);
                    margin-bottom: map-get($spaces, $size);
                    margin-left: map-get($spaces, $size);
                    margin-right: map-get($spaces, $size);
                }
            }
        }
    }
}

.my {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-top: map-get($spaces, $size);
            margin-bottom: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-top: map-get($spaces, $size);
                    margin-bottom: map-get($spaces, $size);
                }
            }
        }
    }
}

.mx {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-left: map-get($spaces, $size);
            margin-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-left: map-get($spaces, $size);
                    margin-right: map-get($spaces, $size);
                }
            }
        }
    }
}

.mt {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-top: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-top: map-get($spaces, $size);
                }
            }
        }
    }
}

.mb {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-bottom: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-bottom: map-get($spaces, $size);
                }
            }
        }
    }
}

.ml {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-left: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-left: map-get($spaces, $size);
                }
            }
        }
    }
}

.mr {
    @each $size in map-keys($spaces) {
        &-#{$size} {
            margin-right: map-get($spaces, $size);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @media only screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
            @each $size in map-keys($spaces) {
                &-#{$size}-#{$breakpoint} {
                    margin-right: map-get($spaces, $size);
                }
            }
        }
    }
}
